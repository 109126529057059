import React, { useState, Fragment } from "react";
import Img from "gatsby-image";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";
import StyledHero from "../../../styled-components/Header/StyledHero";

import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Hero = ({ data }) => {
  console.log(data, "data here");

  const title = data.title;
  const slug = data.slug;
  const publishDate = data.publishDate;
  const description = data.description.description;

  const parseBlogText = () => {
    let period = description.indexOf(".") > -1 ? description.indexOf(".") : 1000;
    let exclamationPoint = description.indexOf("!") > -1 ? description.indexOf("!") : 1000;
    let questionMark = description.indexOf("?") > -1 ? description.indexOf("?") : 1000;
    // console.log(Math.min(period, exclamationPoint, questionMark))
    return Math.min(period, exclamationPoint, questionMark);
  };

  return (
    <StyledHero>
      <div className='hero'>
        <Img className='heroImage heroImage-blog' alt={data.heroImage.title} fluid={data.heroImage.fluid} />
        <div className='blog-header-wrapper'>
          <div className='blog-hero-wrapper'>
            <h1>{title}</h1>

            <p>{description.slice(0, parseBlogText())}... </p>

            <Link to={`/blog/${slug}`}>
              <button className='cta cta-main' style={{ padding: "1rem 2rem" }}>
                Read Story
              </button>
            </Link>
          </div>
        </div>
      </div>
    </StyledHero>
  );
};

export default Hero;
