import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";
import { useStaticQuery, graphql } from "gatsby";
import "../allSass/main.css";
import StyledGrid from "../styled-components/Body/StyledGrid";
import ArticlePreview from "../components/article-preview";
import Hero from "../components/pageContent/blog/hero";

const BlogPage = () => {
  const query = useStaticQuery(graphql`
    query BlogQuery {
      allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
        edges {
          node {
            title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            heroImage {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyContentfulFluid
              }
            }
            description {
              description
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `);

  const posts = query.allContentfulBlogPost.edges;

  return (
    <Layout>
      <div style={{ background: "#fff" }}>
        <Helmet title='My ADU Story - Blog' />
        <Hero data={posts[0].node} />

        <h2 style={{ margin: "2rem auto", textAlign: "center", fontWeight: "bold" }}>Recommended Reads</h2>
        <div className='wrapper' style={{ marginBottom: "4rem" }}>
          <StyledGrid two>
            {posts
              .filter((item, index) => index > 0)
              .map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                );
              })}
          </StyledGrid>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPage;
