import styled, { css } from "styled-components";
import { variables } from "../variables";
import { device } from "../mediaQueries";

export default styled.ul`
  list-style: none;
  display: grid;
  background-color: transparent;

  ${(props) =>
    props.calculator &&
    css`
      input,
      select,
      div,
      .calc-section-title {
        max-width: 300px;
        min-width: 220px;
      }

      li {
        /* width: fit-content; */
        padding: 0 !important;
        margin: 0 auto;
      }

      h5 {
        font-weight: bold;
        font-size: 0.95em;
        align-self: flex-start;
      }

      input,
      select {
        margin-bottom: 2rem;
      }

      .term {
        font-size: 22px;
        padding-left: 1rem;
        font-weight: bold;
        color: ${variables.colorSecondary};
        text-align: right;
      }
    `}

  ${(props) =>
    props.two &&
    css`
      grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
      /* grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); */
      /* padding: 4rem; */
      grid-gap: 10vmin;
      /* justify-content: space-between; */
    `}

    ${(props) =>
    props.three &&
    css`
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      /* grid-gap: 5vmin; */
      li {
        padding: 1.2rem;
      }
    `}

    @media ${device.phone} {
    margin: 4rem 0;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-row-gap: 3rem;

    li {
      padding: 0;
    }
  }
`;
