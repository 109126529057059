import styled from "styled-components";
import { device } from "../mediaQueries";
import { variables } from "../variables";

export default styled.div`
  position: relative;

  .heroWrapper {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    // background-color: rgba($color-black, 0.5);
    background-color: radial-gradient(rgba(${variables.colorGreyDark1}, 0.5), rgba(${variables.colorBlack}, 0.7));
    display: grid;
    align-content: center;
    /* justify-content: center; */

    .contactHero-container {
      z-index: 5;
      margin: 0 auto;
      max-width: 1200px;
      display: flex;

      @media (max-width: 1000px) {
        align-items: center;
        flex-direction: column;
        margin-top: 8rem;
        .container-item {
          width: 80% !important;
          padding: 1.5rem !important;
        }
      }

      .container-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        min-width: 300px;
        margin: 1rem;
        padding: 6rem 3rem 3rem;
        display: flex;

        &-1 {
          background-color: ${variables.colorWhite};
          color: ${variables.colorGreyDark2};
          /* z-index: 1000; */
          @media (max-width: 1000px) {
            padding: 1rem;
            margin: 0.5rem;
            margin-top: 66px;
            transition: all 1s !important;
            transform: scale(0.8) !important;
            button {
              margin: 0.75rem auto !important;
              padding: 0.75rem 3rem !important;
            }
            input {
            }

            h1 {
              font-size: 32px !important;
            }

            input {
              height: 16px;
            }
          }
        }

        &-2 {
          h1 {
            font-size: 44px;
          }
          h2 {
            font-size: 32px;
          }
        }

        h1 {
          font-size: 36px;
          font-weight: bold !important;
          margin-bottom: 1rem;
        }

        .MuiTextField-root {
          /* max-width: 350px; */
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  @media ${device.tabPort} {
    /* margin-left: 1rem; */
    min-width: 50% !important;
    /* max-height: 350px; */

    img {
      /* max-height: 350px; */
    }
  }
`;
